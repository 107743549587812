import * as React from "react"
import LayoutCollectionNew from "../components/collection-page-layout"

const CollectionPage = () => {
  return (

    <LayoutCollectionNew />


  )
}

export default CollectionPage